import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import { getCampaignAmount } from "../services/amount.service";

const { vnListadoCampana: viewName, vfVerListadoCampana: viewFunction } =
  viewTypes;
const userName = localStorage.getItem("userName");

// Fetch campaign amount
const fetchCampaignAmount = async (id) => {
  const response = await getCampaignAmount(
    id,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchCampaignAmount(id) {
  return useQuery({
    queryKey: ["camount", id],
    queryFn: () => fetchCampaignAmount(id),
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
/** */
