export const reportTypes = {
  error: "ERROR",
  notRequest: "NOT_REQUESTED",

  requested: "REQUESTED",
  building: "BUILDING",

  partial: "PARTIAL",
  ok: "OK",
};
