import http from "../common/components/http-common";

class VoicebotService {
  ObtenerVoicebots(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let params = {
      field: data.field,
      size: data.size,
      page: data.page,
      direction: data.direction,
      nombre: data.nombre,
      activo: data.activo,
      tipo: data.tipo,
      userId: data.userId,
    };

    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: params,
    };
    return http.get<any>("/v1/voicebot", config);
  }

  borrarVoicebot(id, userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };

    return http.delete<any>("/v1/voicebot/" + id, config);
  }

  toggleVoicebot(id, userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };

    return http.patch<any>("/v1/voicebot/" + id + "/toggle-activation", config);
  }

  getVoicebot(id, userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.get("/v1/voicebot/" + id, config);
  }

  crearVoicebot(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post("/v1/voicebot", data, config);
  }

  editarVoicebot = (data, userName, viewName, viewFunction) => {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.put<any>(`/v1/voicebot/${data.id}`, data, config);
  };
}

export default new VoicebotService();
