import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useCampaignStore } from "../../../store/campaignStore";
import { classNames } from "primereact/utils";
import { formTypes } from "../../../types/form.type";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { ContactosUpload } from "../../dialogs/contactosUpload/ContactosUpload";
import { InputTextarea } from "primereact/inputtextarea";
import { ValidaCombosSelectors } from "../../dialogs/clientStuff/ValidaCombosSelectors";
import { HorariosSelector } from "../../dialogs/horariosSelector/HorariosSelector";
import { downloadFile } from "../../../services/campaign.service";
import { viewTypes } from "../../../common/types/views.type";

export const FormSaliente = ({
  submitted,
  retornoUserSelector,
  handleVoicebot,
  getTemplate,
  handleVendors,
  handleAsterisksVendors,
}) => {
  const { vnListadoCampana: viewName, vfVerListadoCampana: viewFunction } =
    viewTypes;
  const userName = localStorage.getItem("userName");

  const {
    validaSelects,
    campaign,
    updateCampaignProp,
    selectedVoicebot,
    selectedVendor,
    selectedAsterisks,
    typeForm,
    errors,
    validateDefault,
    voicebots,
    users,
    campaignUser,
    voicebotCompleto,
  } = useCampaignStore((state) => state);

  const { addSaliente, editSaliente, editEntrante } = formTypes;
  const toast = useRef(null);

  const fakeStyle = {
    border: "1px solid #ced4da",
    color: "#ced4da",
    padding: "6px",
    borderRadius: "3px",
  };
  const labelFakeStyle = {
    color: "#ced4da",
  };

  const getFile = () => {
    downloadFile(
      campaign.fileContact.filename,
      campaign.id,
      campaign.surveyTipo,
      userName,
      viewName,
      viewFunction
    );
  };

  return (
    <div className="body">
      <Toast ref={toast} />

      <div className="column">
        <div className="row">
          <div className="column">
            <div className="field">
              <label htmlFor="nombre">Nombre</label>
              <InputText
                value={campaign.name}
                autoFocus
                onChange={(e) => updateCampaignProp("name", e.target.value)}
                className={classNames({
                  "p-invalid": submitted && errors.name,
                })}
              />
              {errors.name && (
                <small className="p-error block">{errors.name}</small>
              )}
            </div>
          </div>

          <div className="column">
            <div className="row">
              <div className="field tercio">
                <label htmlFor="reintentos">Reintentos</label>
                {typeForm === editSaliente || typeForm === addSaliente ? (
                  <>
                    <InputText
                      type="number"
                      min={1}
                      max={validateDefault.reintentos}
                      value={campaign.reintentos}
                      onChange={(e) =>
                        updateCampaignProp("reintentos", e.target.value)
                      }
                      className={classNames({
                        "p-invalid": submitted && errors.reintentos,
                      })}
                    />
                    {errors.reintentos && (
                      <small className="p-error block">
                        {errors.reintentos}
                      </small>
                    )}
                  </>
                ) : (
                  <div>
                    <i
                      className="pi pi-spin pi-spinner"
                      style={{ marginTop: "4px" }}
                    ></i>
                  </div>
                )}
              </div>

              <div className="field switch tercio">
                <InputSwitch
                  checked={campaign.esVisible}
                  onChange={(e) => updateCampaignProp("esVisible", e.value)}
                />
                <label htmlFor="grabarLlamadas">Visible</label>
              </div>
            </div>
          </div>
        </div>

        {typeForm === addSaliente ? (
          <div className="field download">
            <label htmlFor="usuario">Usuario</label>
            <Dropdown
              value={users.find((c) => c.id === campaign.userId)}
              onChange={(e) => retornoUserSelector(e.value)}
              options={users}
              filter
              optionLabel="name"
              placeholder="Seleciona el usuario"
            />
          </div>
        ) : (
          <div
            className="filed"
            style={{ marginTop: "8px", marginBottom: "16px" }}
          >
            <label htmlFor="fileName">
              Usuario: <strong>{campaignUser && campaignUser}</strong>
            </label>
          </div>
        )}

        {typeForm === addSaliente ? (
          <div className="field download">
            <label htmlFor="voicebot">Voicebot</label>
            <Dropdown
              value={selectedVoicebot}
              onChange={(e) => handleVoicebot(e.value)}
              options={voicebots.filter((item) => item.tipo === "saliente")}
              optionLabel="nombre"
              placeholder="Seleccionar"
              filter
              disabled={typeForm === editSaliente}
              className={classNames({
                "p-invalid": submitted && errors.selectedVoicebot,
              })}
            />

            <Button
              onClick={() => getTemplate()}
              className="download"
              disabled={!selectedVoicebot || typeForm === editSaliente}
              tooltip="Descargar template"
            >
              <i className="pi pi-cloud-download"></i>
            </Button>
            {errors.selectedVoicebot && (
              <small className="p-error block">{errors.selectedVoicebot}</small>
            )}
          </div>
        ) : (
          <div
            className="filed"
            style={{ marginTop: "8px", marginBottom: "16px" }}
          >
            <label htmlFor="fileName">
              Voicebot: <strong>{campaign.surveyName}</strong>
            </label>
          </div>
        )}

        <div className="sep"></div>

        <div className="row">
          <div className="column">
            <div className="field switch">
              <InputSwitch
                checked={campaign.grabarLlamadas}
                onChange={(e) => updateCampaignProp("grabarLlamadas", e.value)}
              />
              <label htmlFor="grabarLlamadas">Grabar llamadas</label>
            </div>
            <div className="field switch">
              <InputSwitch
                checked={campaign.grabarTransferencia}
                onChange={(e) =>
                  updateCampaignProp("grabarTransferencia", e.value)
                }
              />
              <label htmlFor="grabarTransferencia">Grabar transferencias</label>
            </div>
          </div>

          <div className="column">
            <div className="field switch">
              <InputSwitch
                checked={campaign.activarAMD}
                onChange={(e) => updateCampaignProp("activarAMD", e.value)}
              />
              <label htmlFor="activarAmd">Activar AMD</label>
            </div>
            <div className="field switch">
              <InputSwitch
                checked={campaign.procesarPBI}
                onChange={(e) => updateCampaignProp("procesarPBI", e.value)}
              />
              <label htmlFor="procesarPBI">Procesar PBI</label>
            </div>
          </div>
        </div>

        <div className="sep"></div>

        <div className="row">
          <div className="column">
            <div className="field">
              <label htmlFor="proveedor">Proveedor</label>
              <Dropdown
                value={selectedVendor}
                onChange={(e) => handleVendors(e.value, false)}
                options={campaign.vendors}
                optionLabel="name"
                placeholder="Seleccionar"
              />
            </div>
            <div className="field">
              <label htmlFor="cps">CPS</label>
              <InputText
                value={campaign.cps}
                type="number"
                min={1}
                max={validateDefault.cps}
                onChange={(e) => updateCampaignProp("cps", e.target.value)}
                className={classNames({
                  "p-invalid": submitted && errors.cps,
                })}
              />
              {errors.cps && (
                <small className="p-error block">{errors.cps}</small>
              )}
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label htmlFor="canales">Canales</label>
              <InputText
                value={campaign.canales}
                type="number"
                min={1}
                max={validateDefault.canales}
                onChange={(e) => updateCampaignProp("canales", e.target.value)}
                className={classNames({
                  "p-invalid": submitted && errors.canales,
                })}
              />
              {errors.canales && (
                <small className="p-error block">{errors.canales}</small>
              )}
            </div>

            <div className="field">
              <label htmlFor="asterisks">Asterisks</label>
              <MultiSelect
                value={selectedAsterisks}
                onChange={(e) => handleAsterisksVendors(e.value)}
                options={campaign.asterisksVendor}
                optionLabel="serverName"
                placeholder="Seleccionar"
                filter
                className={classNames({
                  "p-invalid": submitted && errors.selectedAsterisks,
                })}
              />
              {errors.selectedAsterisks && (
                <small className="p-error block">
                  {errors.selectedAsterisks}
                </small>
              )}
            </div>
          </div>
        </div>

        <div className="sep"></div>
        {typeForm === editSaliente ? (
          <div className="filed">
            <label htmlFor="fileName">
              Archivo de contactos:{" "}
              <strong>
                {campaign.fileContact && campaign.fileContact.filename}
              </strong>
            </label>
            <Button
              className="file-download"
              icon="pi pi-download"
              onClick={() => getFile()}
            />
          </div>
        ) : (
          <div className="field">
            <ContactosUpload
              disabled={selectedVoicebot ? false : true}
              campaign={campaign}
              selectedVoicebot={selectedVoicebot}
              typeForm={typeForm}
              rand={Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000}
            />
            {errors.file && (
              <small className="p-error block">{errors.file}</small>
            )}
          </div>
        )}

        <div className="sep"></div>
      </div>

      <div className="column">
        <div className="field">
          <label htmlFor="desciption">Comentario</label>
          <InputTextarea
            value={campaign.comentario}
            onChange={(e) => updateCampaignProp("comentario", e.target.value)}
            disabled={typeForm === editSaliente || typeForm === editEntrante}
            rows={5}
            cols={30}
          />
        </div>

        <ValidaCombosSelectors
          campaign={campaign}
          validaSelects={validaSelects}
        />

        <div className="field">
          <label htmlFor="agregar-fechas" className="prev agregar-fechas">
            Agregar fechas
          </label>
          <HorariosSelector
            disabled={voicebotCompleto ? false : true}
            horarios={campaign.horarios}
            typeForm={typeForm}
            status={campaign.estado}
            submitted={submitted}
            errors={errors}
          />
          {errors.horarios && (
            <small className="p-error block">{errors.horarios}</small>
          )}
        </div>
      </div>
    </div>
  );
};
