export const switchEstado = (estado = "") => {
  if (estado === "0") {
    return "Pendiente por procesar";
  }
  if (estado === "1") {
    return "Activa";
  }
  if (estado === "2") {
    return "Pausado";
  }
  if (estado === "3") {
    return "Finalizando";
  }
  if (estado === "4") {
    return "Finalizada";
  }
  if (estado === "5") {
    return "En Espera";
  }
  if (estado === "6") {
    return "Abortada";
  }
  if (estado === "7") {
    return "Bloqueando";
  }
  if (estado === "8") {
    return "Bloqueada";
  }
  if (estado === "202") {
    return "Configurando campaña";
  }
  if (estado === "500") {
    return "Error";
  }
  return "Error";
};

export const switchIndice = (estado = "") => {
  if (estado === "Pendiente por procesar") {
    return "0";
  }
  if (estado === "Activa") {
    return "1";
  }
  if (estado === "Pausado") {
    return "2";
  }
  if (estado === "Finalizando") {
    return "3";
  }
  if (estado === "Finalizada") {
    return "4";
  }
  if (estado === "En Espera") {
    return "5";
  }
  if (estado === "Abortada") {
    return "6";
  }
  if (estado === "Bloqueando") {
    return "7";
  }
  if (estado === "Bloqueada") {
    return "8";
  }
  if (estado === "Configurando campaña") {
    return "202";
  }
  if (estado === "Error") {
    return "500";
  }
  return "Error";
};

export const estadosUtils = [
  { name: "Activa", value: "Activa" },
  { name: "Pausado", value: "Pausado" },
  { name: "Finalizada", value: "Finalizada" },
  { name: "En Espera", value: "En Espera" },
];

export const estadosUtilsCompleto = [
  { name: "Activa", value: "Activa" },
  { name: "Pausado", value: "Pausado" },
  { name: "Finalizada", value: "Finalizada" },
  { name: "Configurando campaña", value: "Configurando campaña" },
  { name: "Error", value: "Error" },
  { name: "En Espera", value: "En Espera" },
  { name: "Pendiente por procesar", value: "Pendiente por procesar" },
  { name: "Finalizando", value: "Finalizando" },
  { name: "Abortada", value: "Abortada" },
  { name: "Bloqueando", value: "Bloqueando" },
  { name: "Bloqueada", value: "Bloqueada" },
];

export const styleEstado = (estado) => {
  let estadoSwitch = estado.toUpperCase();
  switch (estadoSwitch) {
    case "ACTIVA":
      return "new";
    case "PAUSADA":
      return "negotiation";
    case "EN_ESPERA":
      return "renewal";
    case "CONFIGURANDO_CAMPANNA":
      return "proposal";
    case "PENDIENTE_POR_PROCESAR":
      return "renewal";
    case "FINALIZADA":
      return "qualified";
    case "ERROR_BATCH":
      return "unqualified";
    default:
      return "other";
  }
};

export const normalizeName = (estado) => {
  switch (estado) {
    case "ACTIVA":
      return "ACTIVA";
    case "PAUSADA":
      return "PAUSADA";
    case "EN_ESPERA":
      return "EN ESPERA";
    case "CONFIGURANDO_CAMPANNA":
      return "CONFIGURANDO CAMPAÑA";
    case "PENDIENTE_POR_PROCESAR":
      return "PENDIENTE POR PROCESAR";
    case "FINALIZADA":
      return "FINALIZADA";
    case "ERROR_BATCH":
      return "ERROR";
    default:
      return estado;
  }
};

export const styleCombo = (estado) => {
  let estadoSwitch = estado.toUpperCase();
  switch (estadoSwitch) {
    case "ACTIVA":
      return "new";
    case "PAUSADO":
      return "negotiation";
    case "EN ESPERA":
      return "renewal";
    case "CONFIGURANDO CAMPAÑA":
      return "proposal";
    case "PENDIENTE POR PROCESAR":
      return "renewal";
    case "FINALIZADA":
      return "qualified";
    case "ERROR":
      return "unqualified";
    default:
      return "other";
  }
};
