import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import { getCampaignDuration } from "../services/duration.service";

const { vnListadoCampana: viewName, vfVerListadoCampana: viewFunction } =
  viewTypes;
const userName = localStorage.getItem("userName");

// Fetch campaign duration
const fetchCampaignDuration = async (id) => {
  const response = await getCampaignDuration(
    id,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchCampaignDuration(id) {
  return useQuery({
    queryKey: ["cdurat", id],
    queryFn: () => fetchCampaignDuration(id),
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
/** */
