import { useRef } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { useCampaignStore } from "../../../../store/campaignStore";

export const StateSelector = ({ updateStates, index, range }) => {
  const { voicebotCompleto } = useCampaignStore((state) => state);

  const toast = useRef(null);

  function filtrarEstados(estados, range) {
    let seleccionados = null;
    if (range.estadosALlamar) {
      const estadosALlamar = range.estadosALlamar.map(
        (estado) => estado.estado
      );
      seleccionados = estados.filter((obj) =>
        estadosALlamar.includes(obj.estado)
      );
    }
    return seleccionados || [];
  }

  const handleStates = (value) => {
    if (value.length === 0) {
      toast.current.show({
        severity: "warn",
        summary: "Advertencia",
        detail: "Debe seleccionar al menos un estado",
        life: 3000,
      });
    } else {
      updateStates(value, index);
    }
  };

  return (
    <div className="state-selector">
      <Toast ref={toast} />
      <div className="field">
        <label htmlFor="estados" className="prev">
          Estados a rellamar
        </label>
        <MultiSelect
          value={filtrarEstados(voicebotCompleto.survey.estados, range)}
          onChange={(e) => handleStates(e.value)}
          options={voicebotCompleto.survey.estados}
          optionLabel="estado"
          placeholder="Seleccionar"
          filter
        />
      </div>
    </div>
  );
};
