import React, { useState, useRef, useEffect } from "react";
import { TbReportAnalytics } from "react-icons/tb";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {
  reportStatus,
  generateReport,
  downloadReport,
} from "../services/report.service";
import { reportTypes } from "../types/report.type";
import { ReportConfirmationDialog } from "./ReportConfirmationDialog";

export const CompReport = ({ data, userName, viewName, viewFunction }) => {
  const userId = localStorage.getItem("user");
  const toast = useRef(null);

  const { error, notRequest, requested, building, partial, ok } = reportTypes;
  const [estado, setEstado] = useState("");
  const [cargando, setCargando] = useState(false);
  const [first, setFirst] = useState(true);

  const tipoReporte = "VALID_TELEPHONE";
  const { _id: encuestaId, nombre: encuestaNombre } = data;

  useEffect(() => {
    estadoReporte();
  }, [data]);

  let timerPoint;
  const [reportId, setReportId] = useState(null);

  const estadoReporte = () => {
    first && setCargando(true);
    reportStatus(encuestaId, tipoReporte, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data;
          setCargando(false);
          setFirst(false);

          if (parsedData.status === partial || parsedData.status === ok)
            clearInterval(timerPoint);
          setReportId(parsedData.reportId);
          setEstado(parsedData.status);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          // setEstado(notRequest);
          // setCargando(false);
        }
        throw error;
      });
  };

  const generarReporte = () => {
    generateReport(
      encuestaId,
      userId,
      tipoReporte,
      userName,
      viewName,
      viewFunction
    ).then((response: any) => {
      try {
        hideReportConfirmationDialog();
        // console.log(response.data);
      } catch (err) {
        const error = err as { message?: string };
      }
    });
  };

  const requestReport = () => {
    generarReporte();
    toast.current.show({
      severity: "success",
      summary: "Reportes",
      detail: "Se está generando el reporte",
      life: 2000,
    });
    setEstado(requested);
    timerPoint = setInterval(() => estadoReporte(), 2000);
  };

  const descargarReport = () => {
    downloadReport(
      reportId,
      data.nombre,
      "VALID_TELEPHONE",
      userName,
      viewName,
      viewFunction
    );
    toast.current.show({
      severity: "success",
      summary: "Reportes",
      detail: "Se está descargando el reporte",
      life: 2000,
    });
    estadoReporte();
  };

  const [reportConfirmationDialog, setReportConfirmationDialog] =
    useState(false);
  const hideReportConfirmationDialog = () => {
    setReportConfirmationDialog(false);
  };

  const confirmarGeneracion = () => {
    setReportConfirmationDialog(true);
  };

  return (
    <>
      {cargando && (
        <Button
          icon="pi pi-spin pi-spinner"
          className="p-button-rounded mr-2"
          disabled={true}
          style={{
            color: "green",
            fontSize: "18px",
            lineHeight: "1",
            backgroundColor: "white",
            border: "white",
          }}
        />
      )}
      {((!cargando && estado === error) ||
        (!cargando && estado === notRequest)) && (
        <Button
          icon={<TbReportAnalytics />}
          className="p-button-rounded p-button-success mr-2"
          style={{
            fontSize: "18px",
            lineHeight: "1",
            backgroundColor: "#7986CB",
            borderColor: "#7986CB",
          }}
          tooltip="Generar Reporte"
          onClick={() => requestReport()}
          tooltipOptions={{ position: "left" }}
        />
      )}

      {((!cargando && estado === requested) ||
        (!cargando && estado === building)) && (
        <Button
          icon="pi pi-spin pi-spinner"
          className="p-button-rounded p-button-success mr-2"
          disabled={true}
          style={{
            fontSize: "18px",
            lineHeight: "1",
          }}
        />
      )}

      {((!cargando && estado === partial) || (!cargando && estado === ok)) && (
        <>
          <Button
            icon={<AiOutlineCloudDownload />}
            className="p-button-rounded p-button-success mr-2"
            style={{ fontSize: "18px", lineHeight: "1" }}
            tooltip="Descargar Reporte"
            onClick={() => descargarReport()}
            tooltipOptions={{ position: "left" }}
            disabled={reportId === null}
          />
          <Button
            icon={<TbReportAnalytics />}
            className="p-button-rounded mr-2"
            style={{
              marginRight: ".5em",
              fontSize: "18px",
              lineHeight: "1",
              backgroundColor: "#7986CB",
              borderColor: "#7986CB",
            }}
            tooltip="Generar Reporte"
            onClick={() => confirmarGeneracion()}
            tooltipOptions={{ position: "left" }}
          />
        </>
      )}

      <ReportConfirmationDialog
        reportConfirmationDialog={reportConfirmationDialog}
        hideReportConfirmationDialog={hideReportConfirmationDialog}
        generarReporte={requestReport}
        tipoReporte={"VALID_TELEPHONE"}
      />
      <Toast ref={toast} />
    </>
  );
};
