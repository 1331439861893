import { create } from "zustand";

const _campaignEmpty = {
  name: "",
  telefono: "",
  procesarPBI: false,
  userId: localStorage.getItem("user"),
  esVisible: true,
  fileContact: null,
  horarios: [],
  fechaInicio: null,
  fechaFin: null,
  comentario: "",
};

const _errorsReset = {
  name: null,
  reintentos: null,
  selectedVoicebot: null,
  canales: null,
  cps: null,
  selectedAsterisks: null,
  file: null,
  horarios: null,
  telefono: null,
};

export const useCampaignStore = create((set, get) => ({
  validaSelects: [],
  campaignEmpty: _campaignEmpty,
  campaign: null,
  users: [],
  hasVendor: false,

  currentUser: null,
  campaignUser: null,
  buildDefault: true,

  voicebots: [],
  vendors: [],
  selecteVoicebot: null,
  selectedVendor: null,
  selectedAsterisks: null,
  voicebotCompleto: null,

  voicebotsCombo: null,

  validateDefault: {},
  typeForm: null,
  errors: _errorsReset,

  setValidaSelects: (validaSelects) => set({ validaSelects }),
  setVendors: (vendors) => set({ vendors }),
  setHasVendor: (hasVendor) => set({ hasVendor }),

  setCampaign: (data) =>
    set((state) => ({
      campaign: {
        ...state.campaign,
        ...data,
      },
    })),

  updateCampaignProp: (propName, propValue) =>
    set((state) => ({
      campaign: {
        ...state.campaign,
        [propName]: propValue,
      },
    })),

  setCurrentUser: (currentUser) => set({ currentUser }),
  setCampaignUser: (campaignUser) => set({ campaignUser }),
  setUsers: (users) => set({ users }),

  setVoicebotsCombo: (voicebotsCombo) => set({ voicebotsCombo }),

  setVoicebots: (voicebots) => set({ voicebots }),
  setSelectedVoicebot: (selectedVoicebot) => set({ selectedVoicebot }),
  setVoicebotCompleto: (voicebotCompleto) => set({ voicebotCompleto }),
  setSelectedVendor: (selectedVendor) => set({ selectedVendor }),
  setSelectedAsterisks: (selectedAsterisks) => set({ selectedAsterisks }),
  setValidateDefault: (partialValidateDefault) =>
    set((state) => ({
      validateDefault: {
        ...state.validateDefault,
        ...partialValidateDefault,
      },
    })),

  setTypeForm: (typeForm) => set({ typeForm }),
  setErrors: (errors) => set({ errors }),

  resetErrors: () => set({ errors: { ..._errorsReset } }),
}));
