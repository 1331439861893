import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import clientService from "../services/client.service";

const { vnListadoCampana: viewName, vfVerListadoCampana: viewFunction } =
  viewTypes;
const userName = localStorage.getItem("userName");

// Fetch config
const fetchConfig = async () => {
  const response = await clientService.GetConfig();
  return response.data;
};

export function useFetchConfig() {
  return useQuery({
    queryKey: ["config"],
    queryFn: () => fetchConfig(),
    retry: false,
    refetchOnWindowFocus: false,
  });
}

/** */
