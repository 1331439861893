import React, { useEffect, useState } from "react";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { useMediaQuery } from "react-responsive";
import { FilterComponent } from "./FilterComponent";
import moment from "moment";
import { Calendar } from "primereact/calendar";

export const FilterWrapper = ({
  lazyParams,
  setLazyParams,
  initFilters,
  filtros,
  initParams,
  filters,
  setFilters,
  handleChange,
}) => {
  //** **//
  const [selectedFecha, setSelectedFecha] = useState<any>(null);
  const [dates, setDates] = useState<Date | Date[] | undefined>(undefined);

  useEffect(() => {
    comprobarFechas();
  }, [dates]);

  const comprobarFechas = () => {
    if (dates != undefined) {
      if (dates[0] != null && dates[1] != null && dates[0] != selectedFecha) {
        setSelectedFecha(dates[0]);
        let fecha1 = formatFecha(dates[0]);
        let fecha2 = formatFecha(dates[1]);
        updateDate(fecha1, fecha2);
      }
    }
  };

  const updateDate = (fecha1, fecha2) => {
    setLazyParams((previousState) => {
      return {
        ...previousState,
        fechaInicioDesde: fecha1,
        fechaInicioHasta: fecha2,
      };
    });
    changeFilter("fechas", fecha1);
  };

  const formatFecha = (fecha) => {
    let date = moment(fecha);
    return date.format("YYYY-MM-DD");
  };

  const handleDates = (value) => {
    setDates(value);
    changeFilter("dates", value);
  };
  /** */

  const isMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const [countFilters, setCountFilters] = useState(0);

  const fields = Object.keys(initFilters);

  const countActiveFilters = () => {
    const countArray = (campo, count) => {
      if (Array.isArray(campo) && campo.length === 0) {
        return count;
      } else if (campo === "") {
        return count;
      } else {
        return count + 1;
      }
    };
    let _count = 0;
    fields.forEach((field) => {
      _count = countArray(filters[field], _count);
    });
    setCountFilters(_count);
  };

  const changeFilter = (name, value) => {
    setFilters((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
  };

  const cleanFilters = () => {
    setLazyParams(initParams);
    setFilters(initFilters);
    setDates(undefined);
  };

  const cleanEsteFiltro = (propiedad) => {
    if (propiedad === "fechas") {
      setDates(undefined);
      setLazyParams((previousState) => {
        return {
          ...previousState,
          fechaInicioDesde: "",
          fechaInicioHasta: "",
        };
      });
    } else {
      setLazyParams((previousState) => {
        return {
          ...previousState,
          [propiedad]: "",
        };
      });
    }
    changeFilter(propiedad, "");
  };

  useEffect(() => {
    countActiveFilters();
  }, [lazyParams]);

  const headerTemplate = (options) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = options.className;

    return (
      <div className={className}>
        <button
          className={
            countFilters === 0
              ? "filter-button pi pi-filter"
              : "filter-button pi pi-filter-slash"
          }
          disabled={countFilters === 0 ? true : false}
          onClick={() => cleanFilters()}
        ></button>
        {countFilters !== 0 && (
          <span className="count-filter">{countFilters}</span>
        )}
        <span className="filter-title">Filtros</span>
        <button
          className={options.togglerClassName}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
      </div>
    );
  };

  const filtroActivo = (_filtro) => {
    let check: boolean = true;

    const filtro = filters[_filtro];
    if (Array.isArray(filtro) && filtro.length === 0) {
      check = false;
    } else if (filtro === "") {
      check = false;
    }

    return check;
  };

  return (
    <div className="filter-panel">
      <Panel
        headerTemplate={headerTemplate}
        collapsed={isMobile ? true : false}
        toggleable={true}
      >
        <div className="filter-contain">
          {filtros.map((filter, index) => (
            <FilterComponent
              key={index}
              title={filter.title}
              filtro={filter.filtro}
              lazyParams={lazyParams}
              filtroActivo={filtroActivo}
              cleanEsteFiltro={cleanEsteFiltro}
              filters={filters}
              handleChange={handleChange}
            >
              {React.cloneElement(filter.input, {
                onChange: (e) => handleChange(filter.filtro, e.target.value),
              })}
            </FilterComponent>
          ))}

          <FilterComponent
            title="Fecha"
            filtro="fechas"
            lazyParams={lazyParams}
            filtroActivo={filtroActivo}
            cleanEsteFiltro={cleanEsteFiltro}
            filters={filters}
            handleChange={null}
          >
            <Calendar
              value={dates}
              onChange={(e) => handleDates(e.value)}
              selectionMode="range"
              readOnlyInput
              placeholder="dd/mm/aaaa - dd/mm/aaaa"
              dateFormat="dd/mm/yy"
            />
          </FilterComponent>
        </div>
      </Panel>
    </div>
  );
};
