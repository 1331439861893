import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { useCampaignStore } from "../../../store/campaignStore";

export const ValidaCombosSelectors = ({ campaign, validaSelects }) => {
  const { updateCampaignProp } = useCampaignStore((state) => state);

  useEffect(() => {
    armarSelects(validaSelects);
  }, []);

  const [tts, setTts] = useState(null);
  const [validadores, setValidadores] = useState(null);
  const [idiomas, setIdiomas] = useState(null);

  const armarSelects = (data) => {
    /* tts */
    let _tts = [];

    data.tts.forEach((t) => {
      const obj = {
        label: t.name,
        items: [],
      };

      t.voices.forEach((voice) => {
        const v = {
          label: voice,
          value: voice,
        };
        obj.items.push(v);
      });
      _tts.push(obj);
    });

    setTts(_tts);
    /* fin tts */

    /* idiomas */
    let _idiomas = [];

    data.idiomas.forEach((i) => {
      const obj = {
        name: i,
        value: i,
      };

      _idiomas.push(obj);
    });

    setIdiomas(_idiomas);
    /* fin idiomas */

    /* validadores */
    let _validadores = [];

    data.validadores.forEach((i) => {
      const obj = {
        name: i,
        value: i,
      };
      _validadores.push(obj);
    });

    setValidadores(_validadores);
    /* fin validadores */
  };

  const groupedItemTemplate = (option) => {
    return <div style={{ marginLeft: "8px" }}>{option.label}</div>;
  };

  const handleTTs = (e) => {
    const _configuracionTTS = {
      service: getService(e),
      voiceName: e,
    };
    updateCampaignProp("configuracionTTS", _configuracionTTS);
  };

  const getService = (voice) => {
    let _service: string;

    tts.forEach((s) => {
      s.items.forEach((t) => {
        if (t.label === voice) {
          _service = s.label;
        }
      });
    });
    return _service;
  };

  const handleIdiomas = (e) => {
    updateCampaignProp("langTranscript", e);
  };

  const handleValidadores = (value) => {
    updateCampaignProp("telephoneValidation", {
      ...campaign.telephoneValidation,
      name: value,
    });
  };

  const handleActive = (value) => {
    updateCampaignProp("telephoneValidation", {
      ...campaign.telephoneValidation,
      active: value,
    });
  };

  return (
    <>
      <div className="sep"></div>

      <div className="row" style={{ alignItems: "center" }}>
        <div className="column">
          {validadores && (
            <div className="field select">
              <label htmlFor="validadores">Tipo de validador</label>
              <Dropdown
                value={campaign.telephoneValidation.name}
                onChange={(e) => handleValidadores(e.value)}
                options={validadores}
                optionLabel="name"
                placeholder="Elige el validador"
              />
            </div>
          )}
        </div>

        <div className="column">
          <div className="field switch">
            <InputSwitch
              checked={campaign.telephoneValidation.active}
              onChange={(e) => handleActive(e.value)}
            />
            <label htmlFor="active">Activar validador</label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="column">
          {tts && (
            <div className="field">
              <label htmlFor="tts">Voz de TTS</label>
              <Dropdown
                value={campaign.configuracionTTS.voiceName}
                filter
                onChange={(e) => handleTTs(e.value)}
                options={tts}
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                optionGroupTemplate={groupedItemTemplate}
                placeholder="Elige el TTS"
              />
            </div>
          )}
        </div>

        <div className="column">
          {idiomas && (
            <div className="field">
              <label htmlFor="idiomas">Idioma de traducción</label>
              <Dropdown
                value={campaign.langTranscript}
                onChange={(e) => handleIdiomas(e.value)}
                options={idiomas}
                optionLabel="name"
                placeholder="Elige el idioma"
              />
            </div>
          )}
        </div>
      </div>

      <div className="sep"></div>
    </>
  );
};
