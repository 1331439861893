import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

export const ReportConfirmationDialog = ({
  reportConfirmationDialog,
  hideReportConfirmationDialog,
  generarReporte,
  tipoReporte,
}) => {
  const confirmationDialogFooter = (
    <>
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => generarReporte(tipoReporte)}
      />
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideReportConfirmationDialog}
      />
    </>
  );

  return (
    <Dialog
      visible={reportConfirmationDialog}
      style={{ width: "400px", maxWidth: "95%" }}
      header="Generar nuevo reporte"
      modal
      footer={confirmationDialogFooter}
      onHide={hideReportConfirmationDialog}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        <span style={{ marginLeft: "0.5rem" }}>
          Se eliminará el reporte existente,
          <br />
          ¿Deseas continuar?
        </span>
      </div>
    </Dialog>
  );
};
