import http from "../common/components/http-common";

export const obtenerArchivosGuardados = (
  filter,
  userName,
  viewName,
  viewFunction
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
    params: filter,
  };
  return http.get<any>("/v1/fileContact/", config);
};
