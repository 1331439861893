import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useCampaignStore } from "../../../store/campaignStore";
import { classNames } from "primereact/utils";
import { formTypes } from "../../../types/form.type";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { ContactosUpload } from "../../dialogs/contactosUpload/ContactosUpload";
import { InputTextarea } from "primereact/inputtextarea";

export const FormReducido = ({ submitted, handleVoicebot, getTemplate }) => {
  const {
    campaign,
    updateCampaignProp,
    selectedVoicebot,
    typeForm,
    errors,
    voicebots,
  } = useCampaignStore((state) => state);

  const { editSaliente, editEntrante } = formTypes;

  const toast = useRef(null);

  return (
    <div className="body">
      <Toast ref={toast} />

      <div className="column">
        <div className="field">
          <label htmlFor="nombre">Nombre</label>
          <InputText
            value={campaign.name}
            autoFocus
            onChange={(e) => updateCampaignProp("name", e.target.value)}
            className={classNames({
              "p-invalid": submitted && errors.name,
            })}
          />
          {errors.name && (
            <small className="p-error block">{errors.name}</small>
          )}
        </div>

        <div className="field download">
          <label htmlFor="voicebot">Voicebot</label>
          <>
            <Dropdown
              value={selectedVoicebot}
              onChange={(e) => handleVoicebot(e.value)}
              options={voicebots.filter(
                (item) =>
                  item.tipo === "saliente" &&
                  item.userId === localStorage.getItem("user")
              )}
              optionLabel="nombre"
              placeholder="Seleccionar"
              filter
              className={classNames({
                "p-invalid": submitted && errors.selectedVoicebot,
              })}
            />

            <Button
              onClick={() => getTemplate()}
              className="download"
              disabled={!selectedVoicebot || typeForm === editSaliente}
              tooltip="Descargar template"
            >
              <i className="pi pi-cloud-download"></i>
            </Button>
          </>
          {errors.selectedVoicebot && (
            <small className="p-error block">{errors.selectedVoicebot}</small>
          )}
        </div>

        <div className="sep"></div>

        <div className="field">
          <ContactosUpload
            disabled={selectedVoicebot ? false : true}
            campaign={campaign}
            selectedVoicebot={selectedVoicebot}
            typeForm={typeForm}
            rand={Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000}
          />
          {errors.file && (
            <small className="p-error block">{errors.file}</small>
          )}
        </div>
        <div className="sep"></div>
      </div>

      <div className="column">
        <div className="field">
          <label htmlFor="desciption">Comentario</label>
          <InputTextarea
            value={campaign.comentario}
            onChange={(e) => updateCampaignProp("comentario", e.target.value)}
            disabled={typeForm === editSaliente || typeForm === editEntrante}
            rows={5}
            cols={30}
          />
        </div>
      </div>
    </div>
  );
};
