import http from "../common/components/http-common";

export const getCampaignAmount = (
  id,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.get<any>(`/surveyResponse/count/${id}`, config);
};
