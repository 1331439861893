import http from "../common/components/http-common";

export const getCampaignDuration = (
  id,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.get<any>(`/v1/call/campaign/${id}/duration`, config);
};

export const getSurveyDuration = (
  id,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.get<any>(`/v1/call/${id}/duration`, config);
};
