import { useState } from "react";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";

export const DateSelector = ({ disabled, onDateAdd, submitted, errors }) => {
  const currentDate = moment().toDate();
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (event) => {
    const _selectedDate = event.value;
    const currentDate = moment().startOf("day");

    if (
      _selectedDate &&
      !onDateAdd(_selectedDate) &&
      moment(_selectedDate).isSameOrAfter(currentDate)
    ) {
      setSelectedDate(null);
    }
  };

  return (
    <Calendar
      id="selectedDate"
      value={selectedDate}
      onChange={handleDateChange}
      dateFormat="dd-mm-yy"
      showIcon
      minDate={currentDate}
      placeholder={"Seleccionar"}
      disabled={disabled}
      className={classNames({
        "p-invalid": submitted && errors.horarios,
      })}
    />
  );
};
