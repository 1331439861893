import http from "../common/components/http-common";

class UserService {
  ObtenerUsuarios(
    filters,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    const params = {
      pageSize: filters.rows,
      active: filters.active,
    };

    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: params,
    };

    return http.get<any>("/v1/users/", config);
  }

  ObtenerUsuarioById = (
    id,
    userName: string,
    viewName: string,
    viewFunction: string
  ) => {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.get<any>("/v1/users/" + id, config);
  };
}

export default new UserService();
