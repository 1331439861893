import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Range } from "./Range";
import { Toast } from "primereact/toast";

export const Rangos = ({
  date,
  rangos,
  updateStates,
  handleAgregarRango,
  handleRemoveRango,
  verificarDate,
  status,
  typeForm,
}) => {
  const toast = useRef(null);
  const [hourFrom, setHourFrom] = useState(null);
  const [hourTo, setHourTo] = useState(null);

  useEffect(() => {
    setHourFrom(null);
    setHourTo(null);
  }, [date]);

  const generateHours = () => {
    const currentHour = moment().hours();
    const currentMinute = moment().minutes();
    const hours = [];

    const startDate = moment(date).isSame(moment(), "day")
      ? moment().startOf("hour").add(currentMinute, "minutes")
      : moment(date).startOf("day");

    for (let hour = startDate.hours(); hour < 24; hour++) {
      const startMinute = hour === startDate.hours() ? currentMinute : 0;
      for (let minute = startMinute; minute < 60; minute++) {
        const label = moment({ hour, minute }).format("HH:mm");
        const value = moment({ hour, minute }).format("HH:mm");
        hours.push({ label, value });
      }
    }
    return hours;
  };

  const hours = generateHours();

  const handleHourFromChange = (e) => {
    setHourFrom(e.value);
    if (hourTo && moment(e.value, "HH:mm").isAfter(moment(hourTo, "HH:mm"))) {
      setHourTo(null);
    }
  };

  const handleHourToChange = (e) => {
    if (
      hourFrom &&
      moment(e.value, "HH:mm").isAfter(moment(hourFrom, "HH:mm"))
    ) {
      const newRange = { from: hourFrom, to: e.value };
      if (!isRangeOverlapping(newRange)) {
        handleAgregarRango(hourFrom, e.value);
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Advertencia",
          detail: "Los rangos deben ser consecutivos y no superpuestos",
          life: 3000,
        });
      }
      setHourFrom(null);
      setHourTo(null);
    }
  };

  function isRangeOverlapping(rango) {
    const rangoInicio = moment(rango.from, "HH:mm");
    const rangoFin = moment(rango.to, "HH:mm");

    let isOverlapping = false;

    rangos.forEach((hora) => {
      const horaInicio = moment(hora.from, "HH:mm");
      const horaFin = moment(hora.to, "HH:mm");

      if (
        (rangoInicio.isSame(horaInicio) && rangoFin.isSame(horaFin)) ||
        rangoInicio.isBetween(horaInicio, horaFin) ||
        rangoFin.isBetween(horaInicio, horaFin) ||
        horaInicio.isBetween(rangoInicio, rangoFin) ||
        horaFin.isBetween(rangoInicio, rangoFin) ||
        rangoInicio.isBefore(horaInicio)
      ) {
        isOverlapping = true;
      }
    });

    return isOverlapping;
  }

  const getAvailableHoursTo = () => {
    if (hourFrom) {
      const fromTime = moment(hourFrom, "HH:mm");
      const nextMinute = fromTime.clone().add(1, "minute");
      return hours.filter((option) =>
        moment(option.value, "HH:mm").isSameOrAfter(nextMinute)
      );
    }
    return hours;
  };

  return (
    <div className="range-comp">
      <Toast ref={toast} />
      <div className="inner-title">Agregar rangos</div>

      <div className="cont-selectors">
        <Dropdown
          options={hours}
          value={hourFrom}
          onChange={handleHourFromChange}
          placeholder="Hora desde"
          className="hora-selector"
          filter
        />

        <Dropdown
          options={getAvailableHoursTo()}
          value={hourTo}
          onChange={handleHourToChange}
          placeholder="Hora hasta"
          disabled={!hourFrom}
          className="hora-selector"
          filter
        />
      </div>

      <ul>
        {rangos.map((rango, index) => (
          <li key={index}>
            <Range
              range={rango}
              index={index}
              updateStates={updateStates}
              handleRemoveRango={handleRemoveRango}
              verificarDate={verificarDate}
              status={status}
              typeForm={typeForm}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
