import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { StateSelector } from "./StateSlector";
import { formTypes } from "../../../../types/form.type";
import { useCampaignStore } from "../../../../store/campaignStore";

export const Range = ({
  range,
  index,
  updateStates,
  handleRemoveRango,
  verificarDate,
  status,
  typeForm,
}) => {
  const { voicebotCompleto } = useCampaignStore((state) => state);

  useEffect(() => {
    presetearEstados(voicebotCompleto.survey.estados);
  }, []);

  const { addEntrante, editEntrante } = formTypes;

  const [showStateSelector, SetShowStateSelector] = useState(false);
  const handleStateSelector = () => {
    SetShowStateSelector(!showStateSelector);
  };

  let _estadosDefault = [];
  if (typeForm !== addEntrante && typeForm !== editEntrante) {
    _estadosDefault = ["no se pudo contactar", "contestador", "sin respuesta"];
  }

  const presetearEstados = (estados) => {
    if (range.estadosALlamar && range.estadosALlamar.length === 0) {
      const estadosSeleccionados = estados.filter((item) =>
        _estadosDefault.includes(item.estado)
      );
      updateStates(estadosSeleccionados, index);
    }
  };

  return (
    <>
      <div className="range">
        <i className="pi pi-clock"></i>
        {range.from} - {range.to}
      </div>

      <div className="actions">
        {typeForm !== addEntrante && typeForm !== editEntrante && (
          <Button
            icon="pi pi-pencil"
            className="p-button"
            onClick={() => handleStateSelector()}
            disabled={
              !range.new &&
              status !== "CONFIGURANDO_CAMPANNA" &&
              verificarDate(range)
            }
          />
        )}
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemoveRango(index)}
          disabled={
            !range.new &&
            status !== "CONFIGURANDO_CAMPANNA" &&
            verificarDate(range)
          }
        />
      </div>

      <StateSelectedList
        estadosSeleccionados={range.estadosALlamar}
        typeForm={typeForm}
      />

      {showStateSelector && (
        <StateSelector
          updateStates={updateStates}
          index={index}
          range={range}
        />
      )}
    </>
  );
};

const StateSelectedList = ({ estadosSeleccionados, typeForm }) => {
  const { addEntrante, editEntrante } = formTypes;

  return (
    <>
      {" "}
      {typeForm !== addEntrante && typeForm !== editEntrante && (
        <div className="estados-seleccionados">
          {estadosSeleccionados &&
            estadosSeleccionados.map((estado) => (
              <span key={estado.estado}>{estado.estado}</span>
            ))}
        </div>
      )}
    </>
  );
};
