export const viewTypes = {
  vnHome: "HOME",
  vfVerCampanasActivasSalientes: "VER CAMPANAS ACTIVAS SALIENTES",
  vfVerCampanasActivasEntrantes: "VER CAMPANAS ACTIVAS ENTRANTES",
  vfVerCampanasFinalizadas: "VER CAMPANAS FINALIZADAS",

  vnConfiguracion: "CONFIGURACION",
  vfAbmVoicebots: "ABM VOICEBOTS",
  vfAbmCategorias: "ABM CATEGORIAS",
  vfAbmGrupos: "ABM GRUPOS",
  vfAbmLocucion: "ABM LOCUCION",
  vfAbmClientes: "ABM CLIENTES",
  vfAbmUsuarios: "ABM USUARIOS",
  vfAbmAsterisks: "ABM ASTERISKS",
  vfAbmProveedores: "ABM PROVEEDORES",
  vfAbmEntidades: "ABM ENTIDADES",
  vfAbmIntenciones: "ABM INTENCIONES",
  vfAbmBodyWs: "ABM BODY WS",
  vfVerConfiguracion: "VER CONFIGURACION",

  vnListadoCampana: "LISTADO_CAMPANA",
  vfVerReportes: "VER REPORTES",
  vfIrDetalleCampana: "IR DETALLE CAMPANA",
  vfDescargarReportesLis: "DESCARGAR REPORTES",
  vfVerListadoCampana: "VER LISTADO CAMPANA",
  vfVerAltaCampanaSaliente: "VER ALTA CAMPANA SALIENTE",
  vfVerAltaCampanaSalienteReducido: "VER ALTA CAMPANA SALIENTE REDUCIDO",
  vfVerAltaCampanaEntrante: "VER ALTA CAMPANA ENTRANTE",
  vfVerEdicionCampana: "VER EDICION CAMPANA",
  vfVerTodasLasCampanas: "VER TODAS LAS CAMPANAS",
  vfVerDuracion: "VER DURACION",
  vfElimnarCampana: "ELIMINAR CAMPANA",

  vnDetalleCampana: "DETALLE_CAMPANA",
  vfDescargarReportesDet: "DESCARGAR REPORTES",
  vfIrDetalleLlamado: "IR DETALLE LLAMADO",
  vfControlarCampana: "CONTROLAR CAMPANA",
  vfVerDashborad: "VER DASHBOARD",
  vfVerDetalleCampana: "VER DETALLE CAMPANA",

  vnDetalleLlamado: "DETALLE_LLAMADO",
  vfVerAudio: "VER AUDIO",
  vfVerDetalleLlamado: "VER DETALLE LLAMADO",

  vnGeneral: "GENERAL",
  vfEstadoDeCampanas: "ESTADOS DE CAMPANAS",
};
