import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import FileUploader from "./FileUploader";
import { viewTypes } from "../../../common/types/views.type";
import { validarEncabezados } from "../../../services/graph.service";
import { Toast } from "primereact/toast";
import { formTypes } from "../../../types/form.type";
import { useCampaignStore } from "../../../store/campaignStore";
import { obtenerArchivosGuardados } from "../../../services/filecontact.service";

export const ContactosUpload = ({
  disabled,
  campaign,
  selectedVoicebot,
  typeForm,
  rand,
}) => {
  const { updateCampaignProp } = useCampaignStore((state) => state);

  const toast = useRef(null);
  const { vnListadoCampana: viewName, vfVerListadoCampana: viewFunction } =
    viewTypes;

  const { addSaliente, addEntrante } = formTypes;

  const userName = localStorage.getItem("userName");
  const userId = localStorage.getItem("user");

  const _filter = {
    // userId,
    pagesize: 80000,
    idSurvey: selectedVoicebot && selectedVoicebot.surveyId,
  };

  useEffect(() => {
    getArchivosGuardados(_filter, userName, viewName, viewFunction);
  }, [rand]);

  useEffect(() => {
    if (campaign.fileContact === null) {
      cambiarTab(0);
    }
  }, [campaign.fileContact]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [savedFiles, setSavedFiles] = useState(null);
  const [cleanInputFile, setCleanInputFile] = useState(false);
  const [selectedExist, setSelectedExist] = useState(false);

  const getArchivosGuardados = (filter, userName, viewName, viewFunction) => {
    obtenerArchivosGuardados(filter, userName, viewName, viewFunction).then(
      (response: any) => {
        try {
          let parsedData = response.data.fileContacts;
          setSavedFiles(parsedData);
        } catch (err) {
          const error = err as { message?: string };
        }
      }
    );
  };

  const handlePath = (value) => {
    setSelectedExist(value);
    validateHeaders(
      selectedVoicebot.surveyId,
      value.csvHeaders,
      userName,
      viewName,
      viewFunction,
      value,
      "pathContactos"
    );
  };

  const validateHeaders = (
    idSurvey,
    encabezados,
    userName,
    viewName,
    viewFunction,
    value,
    origen
  ) => {
    validarEncabezados(
      idSurvey,
      encabezados,
      userName,
      viewName,
      viewFunction
    ).then((response: any) => {
      try {
        if (response.data) {
          if (origen === "file") {
            updateCampaignProp("fileContact", {
              file: value,
              csvHeaders: encabezados,
            });
          }

          if (origen === "pathContactos") {
            updateCampaignProp("fileContact", {
              csvHeaders: value.csvHeaders,
              idPathContactos: value.id,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Los encabezados son incorrectos",
            life: 3000,
          });
          setCleanInputFile(!cleanInputFile);
        }
      } catch (err) {
        const error = err as { message?: string };
      }
    });
  };

  const cambiarTab = (indice) => {
    setActiveIndex(indice);
  };

  return (
    <>
      <Toast ref={toast} />
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => cambiarTab(e.index)}
      >
        <TabPanel header="Archivo de contactos" disabled={disabled}>
          <FileUploader
            validateHeaders={validateHeaders}
            disabled={disabled}
            selectedVoicebot={selectedVoicebot}
            fileContact={campaign.fileContact}
            cleanInputFile={cleanInputFile}
            setCleanInputFile={setCleanInputFile}
          />
        </TabPanel>
        {(typeForm === addSaliente || typeForm === addEntrante) && (
          <TabPanel header="Seleccionar existente" disabled={disabled}>
            <Dropdown
              value={campaign.fileContact ? selectedExist : null}
              onChange={(e) => handlePath(e.value)}
              options={savedFiles}
              optionLabel="filename"
              placeholder="Seleccionar"
              filter
            />
          </TabPanel>
        )}
      </TabView>
    </>
  );
};
