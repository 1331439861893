import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { styleCombo } from "../utils/table.uitls";
import { Button } from "primereact/button";
import { CompReport } from "./CompReport";
import { ShowView } from "../common/components/ShowView";
import { viewTypes } from "../common/types/views.type";
import { Toolbar } from "primereact/toolbar";
import { FilterPanel } from "./FilterPanel";
import { formTypes } from "../types/form.type";
import { useFetchCampaignDuration } from "../hooks/useDuration";
import { useFetchCampaignAmount } from "../hooks/useAmount";

export const CampaingTable = ({
  customers,
  lazyParams,
  setLazyParams,
  loading,
  footerPage,
  initParams,
  openNew,
  editCampaign,
  configLoading,
  userLoading,
  voicebotsLoading,
  confirmDeleteCampaign,
}) => {
  const {
    vnListadoCampana: viewName,
    vfIrDetalleCampana,
    vfDescargarReportesLis,
    vfVerAltaCampanaSaliente,
    vfVerAltaCampanaSalienteReducido,
    vfVerAltaCampanaEntrante,
    vfVerEdicionCampana,
    vfVerDuracion,
    vfElimnarCampana,
  } = viewTypes;

  const { addSaliente, addEntrante, addSalienteReducido } = formTypes;
  const showDuration = ShowView(vfVerDuracion, viewName);
  const showEliminar = ShowView(vfElimnarCampana, viewName);

  const userName = localStorage.getItem("userName");

  const showDetalle = ShowView(vfIrDetalleCampana, viewName);
  const showDescargar = ShowView(vfDescargarReportesLis, viewName);
  const showAltaSaliente = ShowView(vfVerAltaCampanaSaliente, viewName);
  const showAltaSalienteReducido = ShowView(
    vfVerAltaCampanaSalienteReducido,
    viewName
  );
  const showAltaEntrante = ShowView(vfVerAltaCampanaEntrante, viewName);
  const showEditar = ShowView(vfVerEdicionCampana, viewName);
  const isLoading = configLoading || userLoading || voicebotsLoading;

  const surveyBodyTemplate = (rowData) => {
    return (
      <span style={{ textAlign: "left", display: "block" }}>
        {rowData.surveyName}
      </span>
    );
  };

  const dateBodyTemplate = (rowData) => {
    const fecha = moment(rowData.fechaInicio);
    return fecha.format("DD/MM/YYYY HH:mm");
  };

  const onSort = (event) => {
    setLazyParams((previousState) => {
      return {
        ...previousState,
        sortField: event.sortField,
        direction: event.sortOrder,
      };
    });
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <span
          className="target-icon"
          style={{
            fontSize: "1.4rem",
            marginRight: "8px",
            color: "#2196F3",
          }}
        >
          {rowData.surveyTipo.toLowerCase() === "saliente" ? (
            <i className="pi pi-sign-out"></i>
          ) : (
            <i className="target-icon pi pi-sign-in"></i>
          )}
        </span>
        <span style={{ textAlign: "left" }}>{rowData.nombre}</span>
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span
        className={
          "customer-badge status-" + styleCombo(rowData.estado) + " text-status"
        }
      >
        {rowData.estado}
      </span>
    );
  };

  const casosBodyTemplate = (rowData) => {
    const _casos =
      rowData.estado === "Configurando campaña" &&
      rowData.casosARealizar === "0"
        ? "Pendiente"
        : rowData.casosARealizar;
    return <span>{_casos}</span>;
  };

  const Duration = ({ campaignId }) => {
    const { data, error, isLoading } = useFetchCampaignDuration(campaignId);

    if (isLoading) return <i className="pi pi-spin pi-spinner"></i>;
    if (error) return <span>Error</span>;

    const duration = data ? data.durationCall : 0;
    return <span>{duration}</span>;
  };

  const Amount = ({ campaignId }) => {
    const { data, error, isLoading } = useFetchCampaignAmount(campaignId);

    if (isLoading) return <i className="pi pi-spin pi-spinner"></i>;
    if (error) return <span>Error</span>;

    const amount = data ? data : 0;
    return <span>{amount}</span>;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {showDescargar && rowData.estado.toUpperCase() === "FINALIZADA" && (
          <CompReport
            data={rowData}
            userName={userName}
            viewName={viewName}
            viewFunction={vfDescargarReportesLis}
          />
        )}

        {showEditar && rowData.estado.toUpperCase() !== "FINALIZADA" && (
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success mr-2"
            onClick={() =>
              editCampaign(rowData._id, rowData.surveyTipo.toLowerCase())
            }
            disabled={isLoading}
            tooltip="Editar campaña"
          />
        )}

        {showDetalle && (
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-info mr-2"
            onClick={() => redirectEncuesta(rowData)}
            tooltip="Ir al detalle de la campaña"
            tooltipOptions={{ position: "left" }}
          />
        )}
        {showEliminar && (
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger mr-2"
            onClick={() => confirmDeleteCampaign(rowData)}
            tooltip="Eliminar campaña"
            tooltipOptions={{ position: "left" }}
          />
        )}
      </div>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <>
        <div className="my-2">
          <div className="fontToolClient">Campañas</div>
        </div>
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        {showAltaSalienteReducido && (
          <Button
            label="Nueva campaña"
            className="p-button-success mr-2 new-reducida"
            style={{ padding: ".5rem" }}
            disabled={isLoading}
            onClick={() => openNew(addSalienteReducido)}
            icon={isLoading ? "pi pi-spin pi-spinner" : "pi pi-plus"}
          ></Button>
        )}

        {showAltaSaliente && (
          <Button
            label=""
            className="p-button-success mr-2"
            style={{ padding: ".5rem" }}
            disabled={isLoading}
            onClick={() => openNew(addSaliente)}
            icon={isLoading ? "pi pi-spin pi-spinner" : "pi pi-sign-out"}
          ></Button>
        )}

        {showAltaEntrante && (
          <Button
            label=""
            className="p-button-success mr-2"
            style={{ padding: ".5rem" }}
            disabled={isLoading}
            onClick={() => openNew(addEntrante)}
            icon={isLoading ? "pi pi-spin pi-spinner" : "pi pi-sign-in"}
          ></Button>
        )}
      </>
    );
  };

  const redirectEncuesta = (data) => {
    sessionStorage.setItem("campanaId", data._id);
    sessionStorage.setItem("surveyName", data.surveyName);
    sessionStorage.setItem("campanaName", data.nombre);
    sessionStorage.setItem("campanaState", data.estado);
    sessionStorage.setItem("campanaType", data.surveyTipo.toLowerCase());
    window.location.href = "/detalle";
  };

  return (
    <>
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      <FilterPanel
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        initParams={initParams}
      />

      <DataTable
        value={customers}
        responsiveLayout="stack"
        dataKey="id"
        stripedRows
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.direction}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          bodyClassName={"centerRowData"}
          alignHeader="left"
          field="surveyName"
          sortable
          header="Voicebot"
          body={surveyBodyTemplate}
        />
        <Column
          bodyClassName={"centerRowData"}
          field="fechaInicio"
          sortable
          header="Fecha"
          alignHeader="center"
          body={dateBodyTemplate}
        />
        <Column
          bodyClassName={"centerRowData"}
          field="nombre"
          sortable
          header="Campaña"
          alignHeader="left"
          body={nameBodyTemplate}
        />
        <Column
          bodyClassName={"centerRowData"}
          body={(rowData) => <Amount campaignId={rowData._id} />}
          header="Cantidad de Registros"
          alignHeader="center"
        />
        <Column
          bodyClassName={"centerRowData"}
          field="estado"
          sortable
          header="Estado"
          alignHeader="center"
          body={statusBodyTemplate}
        />
        {showDuration && (
          <Column
            bodyClassName={"centerRowData"}
            body={(rowData) => <Duration campaignId={rowData._id} />}
            header="Tiempo total"
            alignHeader="center"
          />
        )}
        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
      </DataTable>
    </>
  );
};
