import React, { ReactNode } from "react";

interface FilterComponentProps {
  filtro: string;
  title: string;
  lazyParams: any;
  filtroActivo: (filtro: any) => boolean;
  cleanEsteFiltro: (filtro: string) => void;
  filters: any;
  handleChange: (filtro: string, value: string) => void;
  children?: ReactNode;
}

export const FilterComponent: React.FC<FilterComponentProps> = ({
  filtro,
  title,
  lazyParams,
  filtroActivo,
  cleanEsteFiltro,
  children,
}) => {
  return (
    <div className="field">
      <button
        className="filter-clear"
        disabled={!filtroActivo(lazyParams[filtro])}
        onClick={() => cleanEsteFiltro(filtro)}
      >
        <GetMark filtro={filtro} filtroActivo={filtroActivo} />
      </button>
      <label htmlFor={filtro}>{title}</label>
      {children}
    </div>
  );
};

const GetMark = ({ filtro, filtroActivo }) => {
  let check: boolean = filtroActivo(filtro);
  return (
    <i
      className={
        check
          ? "filter-icon selected pi pi-circle-fill"
          : "filter-icon pi pi-circle-fill"
      }
    ></i>
  );
};
