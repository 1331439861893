import http from "../common/components/http-common";
var qs = require("qs");

export const obtenerCampaigns = (
  filters,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
    params: filters,
    paramsSerializer: (p) => {
      return qs.stringify(p, { arrayFormat: "brackets" });
    },
  };
  return http.get("/v2/campaign/", config);
};

export const ObtenerCampaignById = (
  id,
  type,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.get<any>(`/v1/campaign/${type}/${id}`, config);
};

export const guardarCampaign = (
  data,
  reducido,
  type,
  userName,
  viewName,
  viewFunction
) => {
  const partialPath = reducido ? `${type}/reducido` : `${type}`;

  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };

  if (type === "saliente") {
    config.headers["content-type"] = "multipart/form-data";
  }

  return http.post<any>(`/v1/campaign/${partialPath}/`, data, config);
};

export const editarCampaign = (
  data,
  type,
  userName,
  viewName,
  viewFunction
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.put<any>(`/v1/campaign/${type}/${data.id}`, data, config);
};

export const downloadFile = (
  fileName: string,
  campaignId: string,
  type: string,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  const headers = { userName, viewName, viewFunction };
  const tipo = type.toLowerCase();

  return http
    .request({
      url: `/v1/campaign/${tipo}/downloadFile/${campaignId}`,
      method: "get",
      headers: headers,
      responseType: "blob", //important
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};

export const borrarCampaign = (
  id,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };

  return http.delete<any>("/v2/campaign/" + id, config);
};
