import moment from "moment";
import { Button } from "primereact/button";
import { useState } from "react";
import { Rangos } from "./rangos/Rangos";
import "moment/locale/es";
import { formTypes } from "../../../types/form.type";

export const DateInfo = ({
  date,
  handleDeleteDate,
  rangos,
  updateRangos,
  typeForm,
  isNew,
  status,
}) => {
  const { editSaliente, editEntrante } = formTypes;

  const verificarDate = (range?) => {
    const currentDate = moment().startOf("day");
    const inputDate = moment(date).startOf("day");

    if (typeForm === editEntrante || typeForm === editSaliente) {
      if (inputDate.isBefore(currentDate)) {
        return true;
      }

      if (inputDate.isSame(currentDate)) {
        const currentFormattedTime = moment().format("HH:mm");

        if (range) {
          // lo está llamando el rango
          if (isTimeWithinRange(currentFormattedTime, range.from, range.to)) {
            return true;
          }
        } else {
          for (const rango of rangos) {
            const { from, to } = rango;
            if (isTimeWithinRange(currentFormattedTime, from, to)) {
              return true;
            }
          }
        }
      }
    } else {
      return false;
    }
    return false;
  };

  const isTimeWithinRange = (time, from, to) => {
    return (
      (moment(time, "HH:mm").isSameOrAfter(moment(from, "HH:mm")) &&
        moment(time, "HH:mm").isSameOrBefore(moment(to, "HH:mm"))) ||
      moment(time, "HH:mm").isSameOrAfter(moment(from, "HH:mm"))
    );
  };

  const [showRangos, setShowRangos] = useState(false);

  const handleToggleRangos = () => {
    setShowRangos(!showRangos);
  };

  const handleAgregarRango = (desde, hasta) => {
    if (desde && hasta) {
      const nuevoRango = {
        from: desde,
        to: hasta,
        estadosALlamar: [],
        new: true,
      };
      const nuevosRangos = [...rangos, nuevoRango];
      updateRangos(date, nuevosRangos);
    }
  };

  const normalizarStr = (str) => {
    return str.slice(0, 3).toUpperCase();
  };

  const updateStates = (estados, indice) => {
    const _rangos = [...rangos];
    _rangos[indice].estadosALlamar = estados;
    updateRangos(date, _rangos);
  };

  const handleRemoveRango = (index) => {
    const _rangos = [...rangos];
    _rangos.splice(index, 1);
    updateRangos(date, _rangos);
  };

  return (
    <div className="calendar">
      <div className="calendar-comp">
        <div className="day-container">
          <div className="calendar-day">
            <div className="calendar-day-month">
              {normalizarStr(moment(date, "DD-MM-YYYY").format("MMMM"))}
            </div>
            <div className="calendar-date-number">
              {moment(date).format("DD")}
            </div>
          </div>
          <div className="calendar-day-string">
            {moment(date).format("dddd")}
          </div>
        </div>
        <div className="actions">
          {rangos.length > 0 && (
            <div className="indicator">{rangos.length}</div>
          )}
          <Button icon="pi pi-clock" onClick={() => handleToggleRangos()} />
          <Button
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => handleDeleteDate(date)}
            disabled={
              !isNew && status !== "CONFIGURANDO_CAMPANNA" && verificarDate()
            }
          />
        </div>
      </div>

      {showRangos && (
        <Rangos
          date={date}
          rangos={rangos}
          handleAgregarRango={handleAgregarRango}
          handleRemoveRango={handleRemoveRango}
          updateStates={updateStates}
          verificarDate={verificarDate}
          status={status}
          typeForm={typeForm}
        />
      )}
    </div>
  );
};
