import React, { useState, useEffect, useRef } from "react";
import { Paginator } from "primereact/paginator";
import { viewTypes } from "../common/types/views.type";
import { ShowView } from "../common/components/ShowView";
import { CampaingTable } from "./CampaingTable";
import { Panel } from "./_slidePanel/Panel";
import { CampaignPanelContent } from "./CampaignPanelContent";
import {
  borrarCampaign,
  editarCampaign,
  guardarCampaign,
} from "../services/campaign.service";
import { objetoAFormData } from "../utils/objectToFormData";
import { Toast } from "primereact/toast";
import { formTypes } from "../types/form.type";
import { switchEstado, switchIndice } from "../utils/table.uitls";
import { useFetchCampaign, useFetchCampaignById } from "../hooks/useCampaign";
import { useCampaignStore } from "../store/campaignStore";
import { useFetchUser, useFetchUserById } from "../hooks/useUser";
import { useFetchConfig } from "../hooks/useClient";
import { useFetchVoicebot, useFetchVoicebotById } from "../hooks/useVoicebot";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DeleteDialog } from "./dialogs/DeleteDialog";

interface params {
  rows: number;
  page: number;
  sortField: any;
  direction: any;
  nombre: string;
  estado: any;
  fechaInicioDesde: any;
  fechaInicioHasta: any;
  surveys: any;
}

export default function CampaignAbm() {
  const {
    setValidaSelects,
    campaignEmpty,
    setCampaign,
    setSelectedVoicebot,
    setSelectedVendor,
    setSelectedAsterisks,
    setCurrentUser,
    setCampaignUser,
    setTypeForm,
    resetErrors,
    setValidateDefault,
    voicebots,
    setVoicebots,
    users,
    setUsers,
    setVoicebotCompleto,
    campaign,
    setHasVendor,
    currentUser,
    voicebotsCombo,
    setVoicebotsCombo,
    updateCampaignProp,
  } = useCampaignStore((state) => state);

  const { editSaliente, editEntrante, addEntrante, addSaliente } = formTypes;
  const [submitted, setSubmitted] = useState(false);
  const toast = useRef(null);
  const {
    vnListadoCampana: viewName,
    vfVerListadoCampana: viewFunction,
    vfVerTodasLasCampanas,
  } = viewTypes;

  const userName = localStorage.getItem("userName");
  const userId = localStorage.getItem("user");

  const verTodo = ShowView(vfVerTodasLasCampanas, viewName);

  useEffect(() => {
    resetErrors();
    setBuild(userId);
  }, []);

  const initParams: params = {
    rows: 10,
    page: 1,
    sortField: null,
    direction: null,
    nombre: "",
    estado: "",
    fechaInicioDesde: "",
    fechaInicioHasta: "",
    surveys: "",
  };

  /** */
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [lazyParams, setLazyParams] = useState(initParams);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const buildFilterDirection = (direction) =>
    direction === 1 ? "ASC" : "DESC";

  const buildFilterSurveys = () => {
    let _surveys = [];

    if (lazyParams.surveys) {
      lazyParams.surveys.forEach((s) => {
        _surveys.push(s.surveyId);
      });
    }
    return _surveys.length > 0 ? _surveys : null;
  };

  const buildFilterEstados = () => {
    let _estados = [];

    if (lazyParams.estado) {
      lazyParams.estado.forEach((p) => {
        _estados.push(switchIndice(p));
      });
    }
    return _estados.length > 0 ? _estados : null;
  };

  const buildFilterCampaign = (params) => ({
    permitirVista: verTodo,
    direction: params.direction ? buildFilterDirection(params.direction) : null,
    surveys: buildFilterSurveys(),
    userId: userId,
    nombre: params.nombre,
    estados: buildFilterEstados(),
    pagesize: params.rows,
    pagenumber: params.page,
    fechaInicioDesde: params.fechaInicioDesde,
    fechaInicioHasta: params.fechaInicioHasta,
    field: params.sortField,
    surveyTipo: "saliente",
  });

  const [filterCampaign, setFilterCampaign] = useState(
    buildFilterCampaign(initParams)
  );

  useEffect(() => {
    setFilterCampaign(buildFilterCampaign(lazyParams));
  }, [lazyParams]);

  const fetchCampaigns = useFetchCampaign(filterCampaign);

  useEffect(() => {
    if (fetchCampaigns.data) {
      const buildData = (responseData) => {
        return responseData.map((c) => {
          let parsed = {
            _id: c._id,
            nombre: c.nombre,
            estado: switchEstado(c.estado),
            fechaInicio: c.fechaInicio,
            casosARealizar: c.casosARealizar.toLocaleString(),
            surveyName: c.surveyName,
            surveyTipo: "SALIENTE", // c.surveyTipo,
          };
          return parsed;
        });
      };
      setCustomers(buildData(fetchCampaigns.data.campaigns));
      setTotalRecords(fetchCampaigns.data.pagination.count);
    }
  }, [fetchCampaigns.data]);

  useEffect(() => {
    setLoading(fetchCampaigns.isLoading);
  }, [fetchCampaigns.isLoading]);

  /** */
  const _filterVoicebot = {
    userId: currentUser ? currentUser.id : userId,
    size: 8000,
    activo: true,
  };

  const { data: voicebotsData, isLoading: voicebotsLoading } =
    useFetchVoicebot(_filterVoicebot);

  useEffect(() => {
    if (voicebotsData) {
      setVoicebots(voicebotsData.voiceBots);
    }
  }, [voicebotsData, currentUser]);

  /** */

  const _filterVoicebotCombo = {
    userId: !verTodo ? userId : null,
    size: 8000,
    activo: !verTodo ? true : null,
  };

  const {
    data: voicebotsComboData,
    isLoading: voicebotsComboLoading,
    refetch: refetchVoicebotsCombo,
  } = useFetchVoicebot(_filterVoicebotCombo);

  useEffect(() => {
    refetchVoicebotsCombo();
  }, [verTodo]);

  useEffect(() => {
    if (voicebotsComboData) {
      setVoicebotsCombo(voicebotsComboData.voiceBots);
    }
  }, [voicebotsComboData]);
  /** */

  const [voicebotId, setVoicebotId] = useState(null);
  const {
    data: voicebotData,
    isLoading: voicebotLoading,
    error: errorVoicebot,
    refetch: refetchVoicebot,
  } = useFetchVoicebotById(voicebotId);

  useEffect(() => {
    if (voicebotData) {
      setVoicebotCompleto(voicebotData);
    }
  }, [voicebotData]);

  const handleSelectVoicebot = (_voicebot) => {
    if (_voicebot) {
      setSelectedVoicebot(_voicebot);
      setVoicebotId(_voicebot.id);
      refetchVoicebot();
    }
  };
  /** */

  const armarSelects = (data) => {
    const _validaSelects = {
      idiomas: data.idiomas,
      tts: data.tts,
      validadores: data.validadores,
    };
    return _validaSelects;
  };

  const { data: configData, isLoading: configLoading } = useFetchConfig();
  useEffect(() => {
    if (configData) {
      setValidaSelects(armarSelects(configData));
    }
  }, [configData]);

  /** */
  const _filterUser = {
    rows: 8000,
    active: true,
  };

  const fetchUsers = useFetchUser(_filterUser);
  useEffect(() => {
    if (fetchUsers.data) {
      setUsers(fetchUsers.data);
    }
  }, [fetchUsers.data]);

  /** */
  const [usuarioId, setUsuarioId] = useState(null);

  const {
    data: userData,
    isLoading: userLoading,
    error: errorUser,
    refetch: refetchUser,
  } = useFetchUserById(usuarioId);

  useEffect(() => {
    if (userData) {
      setCurrentUser(userData);
      buildUser(userData);
    }
  }, [userData]);

  /* */
  const parseVendor = (data) => {
    const groupedData = {};

    data.forEach(({ vendor, asterisk, esDefault }) => {
      const vendorId = vendor.id;

      if (!groupedData[vendorId]) {
        groupedData[vendorId] = {
          ...vendor,
          asterisks: [],
          esDefault: false,
        };
      }
      groupedData[vendorId].esDefault ||= esDefault;

      groupedData[vendorId].asterisks.push({
        ...asterisk,
        esDefault: !!esDefault,
      });
    });
    return Object.values(groupedData);
  };

  /* build campagin */
  const setBuild = (userId) => {
    setUsuarioId(userId);
    refetchUser();
  };

  const buildUser = (user) => {
    const _client = user.client;

    const _vendors = parseVendor(user.vendorAsterisk);
    const _defaultVendor = obtenerVendorDefault(_vendors);

    handleVendors(_defaultVendor);

    const plusData = {
      vendors: _vendors,
      clientId: _client.id,
      grabarLlamadas: _client.configuracionGrabarLlamadas,
      grabarTransferencia: _client.configuracionGrabarTransferencias,
      activarAMD: _client.configuracionAMD,
      reintentos: _client.reintentos,
      telephoneValidation: {
        name: _client.telephoneValidation,
        active: _client.configurciontelephoneValidation,
      },
      langTranscript: _client.langTranscript,
      configuracionTTS: {
        service: _client.configuracionTTSservice,
        voiceName: _client.configuracionTTSVoiceName,
      },
    };

    setValidateDefault({
      reintentos: _client.reintentos,
    });
    setCampaign({
      userId: user.id,
      ...plusData,
    });
  };

  const obtenerVendorDefault = (array) => {
    const defaultVendor = array.find((item) => item.esDefault === true);
    return defaultVendor !== undefined
      ? defaultVendor
      : array.length > 0
      ? array[0]
      : null;
  };

  const handleVendors = (value) => {
    setSelectedVendor(value);
    const _asterisks = value.asterisks;

    const obtenerAsteriskDefault = (ast) => {
      return ast.find((asterisk) => asterisk.esDefault)
        ? [ast.find((asterisk) => asterisk.esDefault)]
        : ast;
    };

    setCampaign({
      asterisksVendor: _asterisks,
      canales: value.channel,
      cps: value.cps,
      queueName: value.queueName,
      context: value.context,
    });

    setSelectedAsterisks(obtenerAsteriskDefault(_asterisks));
    setValidateDefault({
      canales: value.channel,
      cps: value.cps,
    });
  };

  /** */
  const retornoUserSelector = (selectedUser) => {
    updateCampaignProp("fileContact", null);
    setSelectedVoicebot(null);
    setVoicebotCompleto(null);
    setBuild(selectedUser.id);
  };
  /** */

  const confirmDeleteCampaign = (_campaign) => {
    setCampaign(_campaign);
    setDeleteDialog(true);
  };

  const hideDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const deleteCampaign = () => {
    const _id = campaign._id;
    mutationDel.mutate(_id);
  };
  /** */

  const mutationDel = useMutation({
    mutationFn: (id) => {
      return borrarCampaign(id, userName, viewName, viewFunction);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaign"] });
      hideDeleteDialog();
      toast.current.show({
        severity: "success",
        summary: "Borrar campaña",
        detail: "Eliminada correctamente",
        life: 3000,
      });
    },
    onError: (error) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el borrado",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });
  /** */

  const openNew = (typeForm) => {
    setCampaign(campaignEmpty);
    setTypeForm(typeForm);

    resetErrors();
    setSubmitted(false);

    handleSelectVoicebot(null);
    setHasVendor(true);

    setBuild(userId);
    /** */

    setIsOpen(true);
  };

  /** */
  const [campaignId, setCampaignId] = useState(null);
  const [type, setType] = useState(null);

  const {
    data: campaignData,
    isLoading: campaignLoading,
    error: errorCampaign,
    refetch: refetchCampaign,
  } = useFetchCampaignById(campaignId, type);

  /** */

  useEffect(() => {
    if (campaignData) {
      if (campaignData.horarios === null) {
        campaignData.horarios = [];
      }

      const _diferencias = {
        name: campaignData.nombre,
        procesarPBI: campaignData.inPBI,
      };

      const _campaign = {
        ...campaign,
        ...campaignData,
        ..._diferencias,
      };

      handleSelectVoicebot(
        voicebots.find(
          (voicebot) => voicebot.surveyId === campaignData.idSurvey
        )
      );

      if (type === "saliente") {
        const _vendorEnUser =
          _campaign.vendors.find(
            (vendor) => vendor.id === _campaign.configuracionVendor.id
          ) !== undefined;

        if (_vendorEnUser) {
          const _selectedVendor =
            _campaign.vendors.find(
              (obj) => obj.id === _campaign.configuracionVendor.id
            ) || null;
          setSelectedVendor(_selectedVendor);

          _campaign.asterisksVendor = _selectedVendor.asterisks;
          _campaign.canales = campaignData.configuracionVendor.channel;
          _campaign.cps = campaignData.configuracionVendor.cps;
          _campaign.queueName = campaignData.configuracionVendor.queueName;
          _campaign.context = campaignData.configuracionVendor.context;

          const idsSeleccionados =
            campaignData.configuracionVendor.asterisks.map(
              (asterisk) => asterisk.id
            );
          const _selectedAsterisks = _selectedVendor.asterisks.filter(
            (asterisk) => idsSeleccionados.includes(asterisk.id)
          );
          setSelectedAsterisks(_selectedAsterisks);

          setValidateDefault({
            canales: _selectedVendor.channel,
            cps: _selectedVendor.cps,
          });
          setHasVendor(true);
        } else {
          setHasVendor(false);
        }
      }

      setCampaign(_campaign);
      setCurrentUser(_campaign.userId);

      const _typeForm = type === "saliente" ? editSaliente : editEntrante;
      setTypeForm(_typeForm);

      resetErrors();
      setSubmitted(false);
      setCampaignUser(getCampaignUser(_campaign.userId));

      setVoicebotId(switchId(_campaign.idSurvey));

      setIsOpen(true);
    }
  }, [campaignData]);
  /** */

  const switchId = (surveyId) => {
    const voicebot = voicebotsCombo.find((item) => item.surveyId === surveyId);
    return voicebot ? voicebot.id : null;
  };

  const getCampaignUser = (_id) => {
    const user = users.find((user) => user.id === _id);
    const _client = user && user.client;

    setValidateDefault({
      reintentos: _client.reintentos,
    });

    return user ? user.name : null;
  };

  const editCampaign = (_id, _type) => {
    refetchCampaign();
    setCampaignId(_id);
    setType(_type);
  };
  /** */

  const saveCampaign = (campaign, reducido?: false) => {
    const _type = campaign.surveyTipo.toLowerCase();
    const _campaign =
      _type === "saliente" ? objetoAFormData(campaign) : campaign;

    const _data: mutationCreate = {
      campaign: _campaign,
      reducido: reducido,
      type: _type,
    };

    mutationCreate.mutate(_data);
  };

  const cambiarCampaign = (campaign) => {
    const _data: mutationUpdate = {
      campaign: campaign,
      type: campaign.surveyTipo.toLowerCase(),
    };

    mutationUpdate.mutate(_data);
  };
  /** */

  type ErrorResponse = {
    response?: {
      data?: {
        message?: string;
      };
    };
  };
  const queryClient = useQueryClient();

  type mutationCreate = {
    campaign: string;
    reducido: boolean;
    type: string;
  };

  const mutationCreate = useMutation({
    mutationFn: (_mutation: mutationCreate) => {
      return guardarCampaign(
        _mutation.campaign,
        _mutation.reducido,
        _mutation.type,
        userName,
        viewName,
        viewFunction
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaign"] });
      closePanel();
      toast.current.show({
        severity: "success",
        summary: "Alta realizada correctamente",
        life: 3000,
      });
    },
    onError: (error: unknown) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el alta",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  /** */
  type mutationUpdate = {
    campaign: string;
    type: string;
  };

  const mutationUpdate = useMutation({
    mutationFn: (_mutation: mutationUpdate) => {
      return editarCampaign(
        _mutation.campaign,
        _mutation.type,
        userName,
        viewName,
        viewFunction
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaign"] });
      closePanel();
      toast.current.show({
        severity: "success",
        summary: "Cambio realizado correctamente",
        life: 3000,
      });
    },
    onError: (error: unknown) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el cambio",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  /** */
  useEffect(() => {
    evaluarEditFromMonitoring();
  }, [configLoading, userLoading, voicebotsLoading, voicebotsComboLoading]);

  const evaluarEditFromMonitoring = () => {
    const editCampaignId = sessionStorage.getItem("editCampaignId");
    const editcampaignType = sessionStorage.getItem("editCampaignType");

    const isLoading =
      configLoading || userLoading || voicebotsLoading || voicebotsComboLoading;

    if (editCampaignId && !isLoading) {
      sessionStorage.removeItem("editCampaignId");
      sessionStorage.removeItem("editCampaignType");
      editCampaign(editCampaignId, editcampaignType);
    }
  };

  /**/
  const [isOpen, setIsOpen] = useState(false);

  const closePanel = () => {
    setCampaign(campaignEmpty);
    setCampaignId(null);
    setVoicebotId(null);
    setSelectedVoicebot(null);
    setSelectedVendor(null);
    setVoicebotCompleto(null);
    setCurrentUser(null);
    setCampaignUser(null);
    setHasVendor(false);
    setIsOpen(false);
    resetErrors();
    setSubmitted(false);
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  /** */
  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  return (
    <>
      <Toast ref={toast} />
      {ShowView(viewFunction, viewName) && (
        <div className="card">
          <CampaingTable
            customers={customers}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            loading={loading}
            footerPage={footerPage}
            initParams={initParams}
            openNew={openNew}
            editCampaign={editCampaign}
            configLoading={configLoading}
            userLoading={userLoading}
            voicebotsLoading={voicebotsLoading}
            confirmDeleteCampaign={confirmDeleteCampaign}
          />

          <Panel isOpen={isOpen} closePanel={closePanel}>
            <CampaignPanelContent
              closePanel={closePanel}
              saveCampaign={saveCampaign}
              cambiarCampaign={cambiarCampaign}
              handleVendors={handleVendors}
              submitted={submitted}
              setSubmitted={setSubmitted}
              retornoUserSelector={retornoUserSelector}
              handleSelectVoicebot={handleSelectVoicebot}
              userLoading={userLoading}
            />
          </Panel>
          <DeleteDialog
            deleteDialog={deleteDialog}
            hideDeleteDialog={hideDeleteDialog}
            deleteCampaign={deleteCampaign}
            campaign={campaign}
          />
        </div>
      )}
    </>
  );
}
