import { useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import { viewTypes } from "../../../common/types/views.type";
import { Toast } from "primereact/toast";
import { useCampaignStore } from "../../../store/campaignStore";

const FileUploader = ({
  disabled,
  validateHeaders,
  fileContact,
  selectedVoicebot,
  cleanInputFile,
  setCleanInputFile,
}) => {
  const { updateCampaignProp } = useCampaignStore((state) => state);
  const toast = useRef(null);
  const { vnListadoCampana: viewName, vfVerListadoCampana: viewFunction } =
    viewTypes;
  const userName = localStorage.getItem("userName");
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (fileContact && !fileContact.file) {
      fileInputRef.current.value = "";
    }
  }, [cleanInputFile || fileContact === null]);

  const validateExtension = (fileName) => {
    const patron = /\.(csv|xlsx)$/i;
    return patron.test(fileName);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;

      const workbook = XLSX.read(content, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        raw: true,
      });

      if (validateExtension(file.name)) {
        if (data.length <= 1) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "El archivo no tiene contenido",
            life: 3000,
          });
          setCleanInputFile(!cleanInputFile);
        } else {
          validateHeaders(
            selectedVoicebot.surveyId,
            data[0],
            userName,
            viewName,
            viewFunction,
            file,
            "file"
          );
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "La extensión no es válida",
          life: 3000,
        });
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleTrash = () => {
    fileInputRef.current.value = "";
    updateCampaignProp("fileContact", null);
    setCleanInputFile(!cleanInputFile);
  };

  return (
    <div className="file-upload-container">
      <Toast ref={toast} />
      <div className={disabled ? "button-upload disabled" : "button-upload"}>
        <label htmlFor="file-input" className="file-input-label">
          <span className="file-input-icon">
            <i className="pi pi-plus"></i>
          </span>
          {fileContact && fileContact.file ? fileContact.file.name : "Importar"}
        </label>
        {fileContact && (
          <button className="trash" onClick={() => handleTrash()}>
            <i className="pi pi-trash"></i>
          </button>
        )}
      </div>

      <input
        id="file-input"
        disabled={disabled}
        type="file"
        onChange={handleFileUpload}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
    </div>
  );
};

export default FileUploader;
