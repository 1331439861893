import { useRef } from "react";
import moment from "moment";
import { DateSelector } from "./DateSelector";
import { DateInfo } from "./DateInfo";
import { Toast } from "primereact/toast";
import { useCampaignStore } from "../../../store/campaignStore";
import { formTypes } from "../../../types/form.type";

export const HorariosSelector = ({
  disabled,
  horarios,
  typeForm,
  status,
  submitted,
  errors,
}) => {
  const { updateCampaignProp, voicebotCompleto } = useCampaignStore(
    (state) => state
  );

  const toast = useRef(null);
  const { editSaliente, editEntrante } = formTypes;

  const handleDateAdd = (date) => {
    const _date = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS");

    if (horarios.some((d) => moment(d.date).isSame(_date, "day"))) {
      toast.current.show({
        severity: "warn",
        summary: "Advertencia",
        detail: "No se agregó la fecha porque ya existe",
        life: 3000,
      });
      return false;
    }

    function capitalizarStr(cadena) {
      if (cadena.length === 0) {
        return cadena;
      }
      return cadena.charAt(0).toUpperCase() + cadena.slice(1);
    }

    const _horarios = [
      ...horarios,
      {
        id: moment(date).isoWeekday(),
        day: capitalizarStr(moment(date).format("dddd")),
        date: _date,
        rangos: [],
        new: true,
      },
    ].sort((a, b) => moment(a.date).diff(moment(b.date)));
    updateCampaignProp("horarios", _horarios);
    return true;
  };

  const updateRangos = (date, nuevosRangos) => {
    const _horarios = horarios.map((d) => {
      if (moment(d.date).isSame(date, "day")) {
        return { ...d, rangos: nuevosRangos };
      }
      return d;
    });
    updateCampaignProp("horarios", _horarios);
  };

  const handleDeleteDate = (fecha) => {
    const _horarios = horarios.filter((obj) => obj.date !== fecha);
    updateCampaignProp("horarios", _horarios);
  };

  return (
    <div className="fecha-selector">
      <Toast ref={toast} />
      <DateSelector
        disabled={disabled}
        onDateAdd={handleDateAdd}
        submitted={submitted}
        errors={errors}
      />
      {voicebotCompleto ? (
        <DateList
          horarios={horarios}
          handleDeleteDate={handleDeleteDate}
          updateRangos={updateRangos}
          typeForm={typeForm}
          status={status}
        />
      ) : typeForm === editSaliente || typeForm === editEntrante ? (
        <i className="pi pi-spin pi-spinner mt-3"></i>
      ) : null}
    </div>
  );
};

const DateList = ({
  horarios,
  handleDeleteDate,
  updateRangos,
  typeForm,
  status,
}) => {
  return (
    <div>
      {horarios.map((date) => (
        <DateInfo
          key={date.date}
          rangos={date.rangos}
          date={date.date}
          handleDeleteDate={handleDeleteDate}
          updateRangos={updateRangos}
          typeForm={typeForm}
          isNew={date.new || false}
          status={status}
        />
      ))}
    </div>
  );
};
