import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useCampaignStore } from "../../../store/campaignStore";
import { classNames } from "primereact/utils";
import { formTypes } from "../../../types/form.type";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { HorariosSelector } from "../../dialogs/horariosSelector/HorariosSelector";

export const FormEntrante = ({
  submitted,
  retornoUserSelector,
  handleVoicebot,
  getTemplate,
}) => {
  const {
    campaign,
    updateCampaignProp,
    selectedVoicebot,
    typeForm,
    errors,
    voicebots,
    campaignUser,
    users,
    voicebotCompleto,
  } = useCampaignStore((state) => state);

  const { editSaliente, addEntrante, editEntrante } = formTypes;

  const toast = useRef(null);

  return (
    <div className="body">
      <Toast ref={toast} />

      <div className="column">
        <div className="field">
          <label htmlFor="nombre">Nombre</label>
          <InputText
            value={campaign.name}
            autoFocus
            onChange={(e) => updateCampaignProp("name", e.target.value)}
            className={classNames({
              "p-invalid": submitted && errors.name,
            })}
          />
          {errors.name && (
            <small className="p-error block">{errors.name}</small>
          )}
        </div>

        {typeForm === addEntrante ? (
          <div className="field download">
            <label htmlFor="usuario">Usuario</label>
            <Dropdown
              value={users.find((c) => c.id === campaign.userId)}
              onChange={(e) => retornoUserSelector(e.value)}
              options={users}
              filter
              optionLabel="name"
              placeholder="Seleciona el usuario"
            />
          </div>
        ) : (
          <div
            className="filed"
            style={{ marginTop: "20px", marginBottom: "16px" }}
          >
            <label htmlFor="fileName">
              Usuario: <strong>{campaignUser}</strong>
            </label>
          </div>
        )}

        {typeForm === addEntrante ? (
          <div className="field download">
            <label htmlFor="voicebot">Voicebot</label>
            <Dropdown
              value={selectedVoicebot}
              onChange={(e) => handleVoicebot(e.value)}
              options={voicebots.filter((item) => item.tipo === "entrante")}
              optionLabel="nombre"
              placeholder="Seleccionar"
              filter
              disabled={typeForm === editEntrante}
              className={classNames({
                "p-invalid": submitted && errors.selectedVoicebot,
              })}
            />

            <Button
              onClick={() => getTemplate()}
              className="download"
              disabled={!selectedVoicebot || typeForm === editSaliente}
              tooltip="Descargar template"
            >
              <i className="pi pi-cloud-download"></i>
            </Button>
            {errors.selectedVoicebot && (
              <small className="p-error block">{errors.selectedVoicebot}</small>
            )}
          </div>
        ) : (
          <div
            className="filed"
            style={{ marginTop: "8px", marginBottom: "16px" }}
          >
            <label htmlFor="fileName">
              Voicebot: <strong>{campaign.surveyName}</strong>
            </label>
          </div>
        )}

        <div className="field">
          <label htmlFor="telefono">Teléfono</label>
          <InputText
            value={campaign.telefono}
            onChange={(e) => updateCampaignProp("telefono", e.target.value)}
            className={classNames({
              "p-invalid": submitted && errors.telefono,
            })}
          />
          {errors.telefono && (
            <small className="p-error block">{errors.telefono}</small>
          )}
        </div>

        <div className="sep"></div>

        <div className="row">
          <div className="column">
            <div className="field switch">
              <InputSwitch
                checked={campaign.grabarLlamadas}
                onChange={(e) => updateCampaignProp("grabarLlamadas", e.value)}
              />
              <label htmlFor="grabarLlamadas">Grabar llamadas</label>
            </div>
            <div className="field switch">
              <InputSwitch
                checked={campaign.grabarTransferencia}
                onChange={(e) =>
                  updateCampaignProp("grabarTransferencia", e.value)
                }
              />
              <label htmlFor="grabarTransferencia">Grabar transferencias</label>
            </div>
            <div className="field switch">
              <InputSwitch
                checked={campaign.procesarPBI}
                onChange={(e) => updateCampaignProp("procesarPBI", e.value)}
              />
              <label htmlFor="procesarPBI">Procesar PBI</label>
            </div>
          </div>
        </div>
        <div className="sep"></div>
      </div>

      <div className="column">
        <div className="field">
          <label htmlFor="desciption">Comentario</label>
          <InputTextarea
            value={campaign.comentario}
            onChange={(e) => updateCampaignProp("comentario", e.target.value)}
            disabled={typeForm === editSaliente || typeForm === editEntrante}
            rows={5}
            cols={30}
          />
        </div>

        <div className="field">
          <label htmlFor="agregar-fechas" className="prev agregar-fechas">
            Agregar fechas
          </label>
          <HorariosSelector
            disabled={voicebotCompleto ? false : true}
            horarios={campaign.horarios}
            typeForm={typeForm}
            status={campaign.estado}
            submitted={submitted}
            errors={errors}
          />
          {errors.horarios && (
            <small className="p-error block">{errors.horarios}</small>
          )}
        </div>
      </div>
    </div>
  );
};
